import React, { Component } from 'react';
// @ts-ignore
import Hero from '../assets/hero-sm.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';

class App extends Component {
  render() {
    return (
      <div className="vertical-center">
        <div className="wrapper container text-center">
          <div className="body d-inline-block p-4">
            <img src={Hero} className="hero" title="Foothills Soap" />

            <blockquote className="blockquote mb-0">
              <p><em>Foothills Soap</em></p>

              <footer className="blockquote-footer">Coffee Scrub Soap</footer>

              <div className="links d-flex justify-content-between">
                <a href="https://www.instagram.com/foothillssoap" target="_blank" title="Instagram" className="text-center w-50">
                  <FontAwesomeIcon icon={faInstagram} /> Instagram
                </a>
                <a href="https://www.facebook.com/foothillssoap" target="_blank" title="Facebook" className="text-center w-50">
                  <FontAwesomeIcon icon={faFacebookSquare} /> Facebook
                </a>
              </div>
            </blockquote>
          </div>
        </div>
      </div>
    );
  }
}

export default App;